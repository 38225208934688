import React from "react";
import "./ImageBlock.scss";
import { navigate } from "gatsby";
const ImageBlock = ({ caption, link, image }) => {
  const { uid, type, url } = link;

  const hasLink = uid !== null || url != "";

  return (
    <div
      className={`image-block ${hasLink ? "has-link" : ""}`}
      onClick={() => {
        if (uid) {
          if (type === "article" || "case-study") {
            navigate(`/${type}/${uid}`);
          } else {
            navigate(`/${uid}`);
          }
        } else {
          window.open(url);
        }
      }}
    >
      <figure>
        <img src={image} />
        {caption && caption !== "" && <figcaption>{caption}</figcaption>}
      </figure>
    </div>
  );
};
export default ImageBlock;
