import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { withPreview } from "gatsby-source-prismic";

import Layout from "../components/layout";
import SEO from "../components/seo";

import "./../components/Article.scss";

import NewsHero from "../components/NewsHero";
import VideoPlayer from "../components/VideoPlayer";
import Share from "../components/Share";
import Related from "../components/Related";
import RichContent from "../components/RichContent";
import ImageBlock from "../components/ImageBlock";
import CaseStudyContentSplit from "../components/CaseStudyContentSplit";
import { Waypoint } from "react-waypoint";

export const query = graphql`
  query($id: String!, $cat: String) {
    allPrismicArticle(
      filter: { id: { ne: $id }, data: { categories: { uid: { eq: $cat } } } }
      limit: 3
    ) {
      nodes {
        uid
        data {
          title {
            text
          }
          thumb {
            fluid {
              src
            }
          }
          date
          categories {
            document {
              ... on PrismicArticleCategory {
                id
                data {
                  title
                }
              }
            }
          }
        }
      }
    }
    prismicArticle(id: { eq: $id }) {
      id
      uid
      data {
        meta_description
        meta_title
        canonical {
          uid
          type
        }
        author
        date
        title {
          text
        }
        categories {
          document {
            ... on PrismicArticleCategory {
              id
              data {
                title
              }
            }
          }
        }
        hero_image {
          alt
          fluid(maxWidth: 1800) {
            src
          }
        }
        body {
          ... on PrismicArticleBodyTwoColumn {
            id
            items {
              image {
                alt
                url
              }
            }
            primary {
              theme
              background
              section_title {
                text
              }
              reverse_columns
              headline {
                text
              }
              content {
                html
              }
            }
          }
          ... on PrismicArticleBodyImageBlock {
            id
            primary {
              image {
                alt
                fluid(maxWidth: 1200) {
                  src
                }
              }
              caption {
                text
              }
              image_link {
                type
                uid
                url
              }
            }
          }
          ... on PrismicArticleBodyContentBlock {
            id
            primary {
              content {
                html
              }
            }
          }
          ... on PrismicArticleBodyVideoBlock {
            id
            primary {
              shareable
              video {
                embed_url
                html
              }
            }
          }
          ... on PrismicArticleBodyEmbedBlock {
            id
            primary {
              embed_code {
                text
              }
            }
          }
          ... on PrismicArticleBodyCallToAction {
            id
            primary {
              button_text {
                text
              }
              button_link {
                type
                uid
                url
                target
              }
              button_alignment
            }
          }
        }
      }
    }
  }
`;

const ImageSlice = ({ item }) => {
  const [active, setActive] = React.useState(false);

  const onEnter = () => {
    if (!active) {
      setActive(true);
    }
  };
  return (
    <Waypoint topOffset="20%" bottomOffset="20%" onEnter={onEnter}>
      <div className={`slice waypoint ${active ? "waypoint-active" : ""}`}>
        <ImageBlock
          caption={item.primary.caption.text}
          link={item.primary.image_link}
          image={item.primary.image.fluid.src}
        />
      </div>
    </Waypoint>
  );
};

const ContentSlice = ({ item }) => {
  const [active, setActive] = React.useState(false);

  const onEnter = () => {
    if (!active) {
      setActive(true);
    }
  };
  return (
    <Waypoint topOffset="20%" bottomOffset="20%" onEnter={onEnter}>
      <div className={`slice waypoint ${active ? "waypoint-active" : ""}`}>
        <RichContent content={item.primary.content.html} />
      </div>
    </Waypoint>
  );
};

const VideoSlice = ({ item }) => {
  const [active, setActive] = React.useState(false);

  const onEnter = () => {
    if (!active) {
      setActive(true);
    }
  };
  return (
    <Waypoint topOffset="20%" bottomOffset="20%" onEnter={onEnter}>
      <div className={`slice waypoint ${active ? "waypoint-active" : ""}`}>
        <VideoPlayer videoURL={item.primary.video.embed_url} />
        {item.primary.sharable && <Share />}
      </div>
    </Waypoint>
  );
};

const SplitContent = (props) => {
  const [active, setActive] = React.useState(false);

  const onEnter = () => {
    if (!active) {
      setActive(true);
    }
  };

  const theme = props.content.theme
    ? props.content.theme.toLowerCase()
    : "light";

  return (
    <Waypoint topOffset="20%" bottomOffset="20%" onEnter={onEnter}>
      <div
        className={`article__section theme-${theme} waypoint ${
          active ? "waypoint-active" : ""
        }`}
        style={{ backgroundColor: props.content.background }}
      >
        {props.content.section_title && (
          <h4>{props.content.section_title.text}</h4>
        )}
        <div className="container container-large">
          <CaseStudyContentSplit {...props} />
        </div>
      </div>
    </Waypoint>
  );
};

const EmbedCode = (props) => {
  const [active, setActive] = React.useState(false);

  const onEnter = () => {
    if (!active) {
      setActive(true);
    }
  };

  return (
    <Waypoint topOffset="20%" bottomOffset="20%" onEnter={onEnter}>
      <div
        className={`case-study__section size--small color-gray waypoint ${
          active ? "waypoint-active" : ""
        }`}
      >
        <div className="container case-study-content">
          <div
            className="embedCode"
            dangerouslySetInnerHTML={{ __html: props.content.embed_code.text }}
          ></div>
        </div>
      </div>
    </Waypoint>
  );
};

const BlogCTA = ({ item }) => {
  const [active, setActive] = React.useState(false);

  const onEnter = () => {
    if (!active) {
      setActive(true);
    }
  };

  return (
    <Waypoint topOffset="20%" bottomOffset="20%" onEnter={onEnter}>
      <div
        className={`slice slice-cta text-${item.button_alignment} waypoint ${
          active ? "waypoint-active" : ""
        }`}
      >
        <a
          href={item.button_link.url}
          target={item.button_link.target ? item.button_link.target : "_self"}
          className="button button-large button-pink button-round"
          style={{ margin: "1.5rem 0" }}
        >
          {item.button_text.text}
        </a>
      </div>
    </Waypoint>
  );
};

const Slices = ({ items }) => {
  return items.map((item) => {
    switch (item.__typename) {
      case "PrismicArticleBodyTwoColumn":
        return (
          <div class="col col-md-12">
            <SplitContent content={item.primary} items={item.items} />
          </div>
        );
      case "PrismicArticleBodyContentBlock":
        return (
          <div className="col col-md-10 col-md-offset-1">
            <ContentSlice item={item} />
          </div>
        );
      case "PrismicArticleBodyImageBlock":
        return (
          <div className="col col-md-10 col-md-offset-1">
            <ImageSlice item={item} />
          </div>
        );
      case "PrismicArticleBodyVideoBlock":
        return (
          <div className="col col-md-10 col-md-offset-1">
            <VideoSlice item={item} />
          </div>
        );
      case "PrismicArticleBodyEmbedBlock":
        // console.log(item)
        return (
          <div className="col col-md-10 col-md-offset-1">
            <EmbedCode content={item.primary} />
          </div>
        );
      case "PrismicArticleBodyCallToAction":
        // console.log(item)
        return (
          <div className="col col-md-10 col-md-offset-1">
            <BlogCTA item={item.primary} />
          </div>
        );
      default:
        return null;
    }
  });

  return null;
};

const Article = ({ data, location }) => {
  const articleData = data.prismicArticle.data;

  const meta = {
    descripition: articleData.meta_description,
    title: articleData.meta_title,
    image:
      articleData.hero_image && articleData.hero_image.fluid
        ? articleData.hero_image.fluid.src
        : null,
    uid: articleData.canonical.uid,
    pageType: articleData.canonical.type,
    type: "article",
  };

  useEffect(() => {
    var head = document.getElementsByTagName("head")[0];
    var script = document.createElement("script");
    script.src = "https://www.tiktok.com/embed.js";
    head.appendChild(script);

    var head = document.getElementsByTagName("head")[0];
    var script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    head.appendChild(script);
  }, [articleData]);

  return (
    <Layout isDetail={true} location={location}>
      <SEO meta={meta} description={meta.descripition} title={meta.title} image={meta.image} uid={meta.uid} pageType={meta.pageType} type={meta.type} />
      <div className="article page">
        <NewsHero content={data.prismicArticle.data} />
        <div className="container container-medium modules">
          <div className="row">
            <Slices items={data.prismicArticle.data.body} />
          </div>
          <Related
            label="Related News"
            type="article"
            items={data.allPrismicArticle.nodes}
          />
        </div>
      </div>
    </Layout>
  );
};

Article.query = query;

export default withPreview(Article);
