import React from "react";
import "./NewsHero.scss";
import dayjs from "dayjs";
import Social from "./../lib/Social";

const shareURL = (type) => {
  if (type === "twitter") {
    Social.twitter(
      window.location.href,
      "Check out this article from Colormatics"
    );
  } else {
    Social[type](window.location.href);
  }
};
const NewsHero = ({ content }) => {
  const [active, setActive] = React.useState(false);

  React.useEffect(() => {
    setActive(true);
  });

  return (
    <div className={`news-hero ${active ? "active" : ""}`}>
      <div className="news-hero__label verticle-text">
        <span>Article</span>
      </div>
      <div
        className="news-hero__background"
        style={{
          backgroundImage: `url(${
            content.hero_image.fluid ? content.hero_image.fluid.src : ""
          })`,
        }}
      ></div>
      <div className="container">
        <div className="news-hero__content">
          <div className="news-hero__content-inner">
            <a href="/blog" className="back-btn">
              <img
                src={require("./../assets/svg/arrow-back.svg")}
                alt="Back to Blog"
              />
            </a>
            <div className="meta">
              {content.categories && content.categories.document && (
                <span>
                  <span className="tag">
                    {content.categories.document.data.title}
                  </span>
                </span>
              )}
              <span>
                <span className="date">
                  {dayjs(content.date).format("MMM YYYY").toUpperCase()}
                </span>
                <span className="line">|</span>
                <span className="author">{content.author}</span>
              </span>
            </div>
            <h1 className="heading heading--xxl text--black">
              {content.title.text}
            </h1>
            <div className="social-icons">
              <li>
                <a
                  href="http://facebook.com"
                  onClick={(e) => {
                    e.preventDefault();
                    shareURL("facebook");
                  }}
                  className="social-icon facebook"
                >
                  <img
                    src={require("./../assets/svg/facebook-white.svg")}
                    alt="Facebook"
                  />
                </a>
              </li>
              <li>
                <a
                  href="http://twitter.com"
                  onClick={(e) => {
                    e.preventDefault();
                    shareURL("twitter");
                  }}
                  className="social-icon"
                >
                  <img
                    src={require("./../assets/svg/twitter-white.svg")}
                    alt="Twitter"
                  />
                </a>
              </li>
              <li>
                <a
                  href="http://linked.com"
                  onClick={(e) => {
                    e.preventDefault();
                    shareURL("linkedin");
                  }}
                  className="social-icon facebook"
                >
                  <img
                    src={require("./../assets/svg/linkedin-white.svg")}
                    alt="Linked"
                  />
                </a>
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewsHero;
