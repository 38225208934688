import React from "react";
import "./RichContent.scss";
const RichContent = ({ content }) => {
  return (
    <div
      className="rich-content"
      dangerouslySetInnerHTML={{ __html: content }}
    ></div>
  );
};
export default RichContent;
